
import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import moment from "moment-timezone";
import EditIcon from '@mui/icons-material/Edit';
import { Tabs, Tab } from '@mui/material';
import { Grid, Button, Typography } from '@material-ui/core';
import {
    TextField,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
} from "@mui/material";
import { getAllMessages, searchMessages }  from './api';
import MessageReadOnlyForm from "./messageReadonlyForm";
import SimpleDialog from "../../../common/simpleDialog";
import GenericMessageSearchForm from './genericMessageSearchForm';
import "./index.css";



class AdminMessagingPage extends Component {
     state = {
        messages: [],
        filters: 
            {
                keyword: "",
                startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), 
                endDate: new Date(), 
                emailStatus: "",
                textStatus: ""
            }
        ,
        showMessageDetails: false,
        activeMessage: []
    };
    componentDidMount = async () => {

        await searchMessages(this.state.filters);
        
    };

    displayDate = (val) => {
        if (!val) return "";


        return moment.utc(
            val.endsWith("Z") ? val : `${val}Z`
        )
            .tz("America/New_York")
            .format("M/D/YY h:mm a");
    };

    searchMessages = async (input) => {
        console.log(input);
        const ret = await searchMessages(input);
        console.log(ret);
        this.setState({ messages: ret.data.result })
    }
    openMessageEntry = (message) => {
        this.setState({ showMessageDetails: true, activeMessage: message });
    }
    cancelMessageDetails = (message) => {
        this.setState({ showMessageDetails: false, activeMessage: [] });
    }


  

    render() {
        let messageColumns = [

            {
                Header: "",
                accessor: "id",
                width: 50,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} ><EditIcon onClick={() => this.openMessageEntry(row.original)} /></div>
                ),

            }
            , {
                Header: "Recipient",
                accessor: "recipient",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.recipient}</div>
                ),

            },
            {
                Header: "Sender",
                accessor: "sender",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.sender}</div>
                ),

            },
            {
                Header: "Sent Date",
                accessor: "sentTime",
                width: 100,
                Cell: (row) => {
                    return <div className="dataCell" style={{ textAlign: "center" }}>{this.displayDate(row.value)}</div>;
                },

            }
            , {
                Header: "Subject",
                accessor: "subject",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.subject}</div>
                ),

            },
            {
                Header: "Email Body",
                accessor: "content",
                width: 300,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.content}</div>
                ),

            },
            {
                Header: "Text Body",
                accessor: "textBody",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.textBody}</div>
                ),

            },
            {
                Header: "Email Address",
                accessor: "emailAddress",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.emailAddress}</div>
                ),

            }
            ,
            
            {
                Header: "Email Status",
                accessor: "emailStatus",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.emailStatus}</div>
                ),

            },{
                Header: "Mobile Phone",
                accessor: "mobilePhone",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.mobilePhone}</div>
                ),

            }
            ,
            {
                Header: "Text Status",
                accessor: "textStatus",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.textStatus}</div>
                ),

            }



        ];
        return (
            <div className="admin-admissions-page">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericMessageSearchForm onSubmit={this.searchMessages } />
                    </Grid>
                    <Grid item xs={12}>
                        <ReactTable
                            data={this.state.messages}
                            minRows={0}
                            columns={messageColumns}
                            defaultPageSize={50}
                            className="-striped -highlight tableWrapper"
                        />
                    </Grid>
                </Grid>

                <SimpleDialog open={this.state.showMessageDetails} onClose={this.cancelMessageDetails} title="Message Details" showCloseButton={true} className="ProfileEditDialog">

                    <MessageReadOnlyForm
                        message={this.state.activeMessage}
                    />


                </SimpleDialog>
            </div>
        );
    }
}

export default AdminMessagingPage;