import axios from "axios";

export function getIpeds() {
    return axios.get("/api/services/app/Tagging/GetIpeds");
}
export const getMyProfile = () => {
    return axios.get('/api/services/app/accountManagement/GetMyProfile');
};

export const getAdvisorBasics = (userId) => {
    return axios.get("/api/services/app/Advisors/GetAdvisorBasicsAsync", { params: { userId } });
};
export const getMyAdvisorBasics = () => {
    return axios.get("/api/services/app/Admin/GetCode");
};
//export const getMyAdvisorBasics = () => {
//    return axios.get("/api/services/app/Advisors/GetMyAdvisorBasicsAsync");
//};
export const getPotentialAdvisorSubordinates = (userProfileId) => {
    return axios.get("/api/services/app/Advisors/GetPotentialAdvisorSubordinates", { params: { userProfileId } });
};

export const assignAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignAdvisor", payload);
};

export const assignAdvisorSchool = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignAdvisorSchool", payload);
};

export const unassignAdvisorSchool = (payload) => {
    return axios.post("/api/services/app/Advisors/UnassignAdvisorSchool", payload);
};

export const assignDirectorAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignDirectorAdvisor", payload);
};
export const setDirector = (payload) => {
    return axios.post("/api/services/app/Advisors/SetDirector", payload);
};
export const unassignDirectorAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/UnassignDirectorAdvisor", payload);
};

export const getCrusadersByAdvisor = (userId) => {
    return axios.get("/api/services/app/Advisors/GetCrusadersByAdvisor", { params: { userId } });
};

export const getMyCrusadersByAdvisor = () => {
    return axios.get(`/api/services/app/Advisors/GetMyCrusadersByAdvisor`, {
        headers: {
            'Cache-Control': 'no-cache'
        },
        params: {
            _t: new Date().getTime()  
        }
    });
};

export const addAdvisorEvent = (payload) => {
    return axios.post("/api/services/app/Advisors/AddAdvisorEvent", payload);
};
export const updateAdvisorEvent = (payload) => {
    return axios.put("/api/services/app/Advisors/UpdateAdvisorEvent", payload);
};
export const addAdvisorEventTag = (payload) => {
    return axios.post("/api/services/app/Advisors/AddAdvisorEventTag", payload);
};

export const addAdvisorEventAttendance = (payload) => {
    return axios.post("/api/services/app/Advisors/AddAdvisorEventAttendance", payload);
};

export const getAllSchools = (cancelToken) => {
    return axios.get("/api/services/app/School/GetAllActive", { cancelToken });
};

export function getCityList() {
    return axios.get('/api/services/app/City/GetList');
}

export const sendAdvisorMessage = (payload) => {
    return axios.post("/api/services/app/Advisors/SendAdvisorMessage", payload);
};
export const retrieveAdditionInfo = (payload) => {
    return axios.post("/api/services/app/Advisors/RetrieveAdditionInfo", payload);
};


export const updateCrusaderUser = (payload) => {
    return axios.post("/api/services/app/Advisors/UpdateCrusaderUser", payload);
};
export const updatePassword = (payload) => {
    return axios.post("/api/services/app/Advisors/UpdatePassword", payload);
};
export const updateActiveStatus = (payload) => {
    return axios.post("/api/services/app/Advisors/UpdateActiveStatus", payload);
};
export const resendEmail = (payload) => {
    return axios.post("/api/services/app/Advisors/ResendEmail", payload);
};
export const checkUsernameExists = (payload) => {
    return axios.post("/api/services/app/Advisors/CheckUsernameExists", payload);
};
export const updateParentUser = (payload) => {
    return axios.post("/api/services/app/Advisors/updateParentUser", payload);
};

export const getUserAdvisorEventHistory = (userProfileId) => {
    return axios.get("/api/services/app/Advisors/GetUserAdvisorEventHistory", { params: { userProfileId } });
};

export const getUserMessageHistory = (UserProfileId) => {
    return axios.get("/api/services/app/Advisors/GetUserMessageHistory", { params: { UserProfileId } });
};

export const getAdvisorEvent = (id) => {
    return axios.get("/api/services/app/Advisors/getAdvisorEvent", { params: { id } });
};

export const getStudentGrades = (StudentProfileId) => {
    return axios.get("/api/services/app/Advisors/GetStudentGrades", { params: { StudentProfileId } });
};


export const addStudentGrades = (payload) => {
    return axios.post("/api/services/app/Advisors/AddStudentGrades", payload);
};
export const updateStudentGrades = (payload) => {
    return axios.post("/api/services/app/Advisors/UpdateStudentGrades", payload);
};
export const deleteStudentGrades = (payload) => {
    return axios.delete("/api/services/app/Advisors/DeleteStudentGrades", payload);
};

export function returnAdvisorTags(input) {

    return axios.post("/api/services/app/Tagging/ReturnAdvisorTags", input);
}
export const updateStudentProfile = (payload) => {
    return axios.put("/api/services/app/Advisors/UpdateStudentProfile", payload);
};

export const getAllInstitutions = (cancelToken) => {
    return axios.get("/api/services/app/Institution/GetAllActive", { cancelToken });
};

export const getStudentById = (userProfileId) => {
    return axios.get("/api/services/app/Advisors/GetStudentById", { params: { userProfileId } });
};

export const getParentById = (userProfileId) => {
    return axios.get("/api/services/app/Advisors/GetParentById", { params: { userProfileId } });
};
export function advisorSearch(input) {

    return axios.post("/api/services/app/Advisors/AdvisorSearch", input);
};
export function updateAdvisorEventHours(input) {

    return axios.post("/api/services/app/Advisors/UpdateAdvisorEventHours", input);
};

export const getAdvisorEventCreditHours = (advisorEventId) => {
    return axios.get("/api/services/app/Advisors/GetAdvisorEventCreditHours", { params: { advisorEventId } });
};

export const getAdminLog = (dates) => {
    return axios.post("/api/services/app/Advisors/GetAdminLog", dates);
};

export const getUsersLog = (payload, dates) => {
    return axios.post("/api/services/app/Advisors/GetUsersLog", { users: payload, dates: dates });
};

export const searchUsersLog = (payload, dates, searchText, tags) => {
    return axios.post("/api/services/app/Advisors/SearchUsersLog", { users: payload, dates: dates, searchText: searchText, tags: tags });
};
export const getUserEventhistory = (userProfileId) => {
    return axios.get("/api/services/app/ProgramEvent/GetUserEventhistory", { params: { userProfileId } });
};
export const getMilestoneTags = () => {
    return axios.get("/api/services/app/objectives/GetMilestoneTags");
};