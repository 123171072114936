import React from "react";
import {
    Grid,
    TextField,
    AppBar,
    Tabs,
    Tab,
    Box,
    Typography
} from "@mui/material";
import moment from "moment-timezone";


class MessageReadOnlyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    handleTabChange = (event, newValue) => {
        this.setState({ selectedTab: newValue });
    };
    

displayValue = (val) =>
    val !== null && val !== undefined ? val.toString() : "";

    displayDate = (val) => {
        if (!val) return "";

        
        return moment.utc(
            val.endsWith("Z") ? val : `${val}Z` 
        )
            .tz("America/New_York")
            .format("M/D/YY h:mm a");
    };
render() {
    const { message } = this.props;
    const { selectedTab } = this.state;

    if (!message) {
        return null;
    }
    console.log(message);
    return (
        <Grid container spacing={2}>
            {/* Title */}
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Message Details
                </Typography>
            </Grid>

            {/* Always-visible fields: ID and UserProfileId */}
            <Grid item xs={12} md={6}>
                <TextField
                    label="ID"
                    value={this.displayValue(message.id)}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label="User Profile ID"
                    value={this.displayValue(message.userProfileId)}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Sender"
                    value={this.displayValue(message.sender)}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Sent Time"
                    value={this.displayDate(message.sentTime)}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            {/* Tabs Container */}
            <Grid item xs={12}>

                <Tabs
                    value={selectedTab}
                    onChange={this.handleTabChange}
                    aria-label="Activity Sections"
                    variant="fullWidth"
                    sx={{
                        '.MuiTabs-flexContainer': {
                            justifyContent: 'space-evenly',
                        },
                        '.MuiTab-root': {
                            border: '1px solid #000',
                            borderBottom: 'none',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            marginRight: '4px',
                            '&:last-child': {
                                marginRight: 0,
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#123225',
                                color: '#fff',
                            },
                            '&:hover': {
                                backgroundColor: '#123225',
                                color: '#fff',
                                opacity: 1,
                            },
                        },
                    }}
                >
                    <Tab label="Email Info" />
                    <Tab label="Text Info" />
                </Tabs>


                {/* EMAIL TAB */}
                {selectedTab === 0 && (
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Is Email"
                                    value={this.displayValue(message.isEmail)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email Address"
                                    value={this.displayValue(message.emailAddress)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    label="Subject"
                                    value={this.displayValue(message.subject)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Content"
                                    value={this.displayValue(message.content)}
                                    multiline
                                    minRows={3}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Is Email Sent"
                                    value={this.displayValue(message.isEmailSent)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Date Email Sent"
                                    value={this.displayDate(message.dateEmailSent)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Is Email Delivered"
                                    value={this.displayValue(message.isEmailDelivered)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Date Email Read"
                                    value={this.displayDate(message.dateEmailDelivered)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Is Email Read"
                                    value={this.displayValue(message.isEmailRead)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Date Email Read"
                                    value={this.displayDate(message.dateEmailRead)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email Status"
                                    value={this.displayValue(message.emailStatus)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Email Status reason"
                                    value={this.displayValue(message.emailStatusReason)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>

                        </Grid>
                    </Box>
                )}

                {/* TEXT TAB */}
                {selectedTab === 1 && (
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Is Text"
                                    value={this.displayValue(message.isText)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Mobile Phone"
                                    value={this.displayValue(message.mobilePhone)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Text Body"
                                    value={this.displayValue(message.textBody)}
                                    multiline
                                    minRows={3}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Is Text Sent"
                                    value={this.displayValue(message.isTextSent)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Date Text Sent"
                                    value={this.displayDate(message.dateTextSent)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Is Text Delivered"
                                    value={this.displayValue(message.isTextDelivered)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Date Text Delivered"
                                    value={this.displayDate(message.dateTextDelivered)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Text Status"
                                    value={this.displayValue(message.textStatus)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Text Status reason"
                                    value={this.displayValue(message.textStatusReason)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
}

export default MessageReadOnlyForm;
