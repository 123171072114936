import React, { Component, } from "react";
import Link from "@material-ui/core/Link";

import { Tabs, Tab } from '@mui/material';

import { Grid, Button, Typography } from '@material-ui/core';
import Export2PDF from "../../common/export";
import "./tabbedProfile.css";
import { Progress, ActivitiesList, EventsList, Notes, GeneralInfoForm, ParentProgress, ParentProgress2Exp } from "./components";
import AdvisorContext from "../context/context";
import { getUserAdvisorEventHistory, getParentById, getUserEventhistory, getUserMessageHistory } from "../api.js";
import { eventType } from "../enums.js";
import enumHelper from "../../support/logic/enumHelper";
import { Language } from "../../admissions/logic/enums";
import LoadingIndicator from "../../common/loadingIndicator";
import moment from "moment";

const languageOptions = enumHelper
    .getSelectOptionsList(Language);


class AdvisorParent extends Component {

    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {

            
            activeProfile: this.props.profile,
            tab: 0,
            infoVis: "tabVisible",
            eventsVis: "tabHidden",
            activityVis: "tabHidden",
            notesVis: "tabHidden",
            progressVis: "tabHidden",
            
            changed: false,
            description: "",
            activity: [],
            notes: [],
            events: [],
            messages: [],
            profileLoaded: false,
            exportTitle: "",
        };
    }



    componentDidMount = async () => {

        const prof = await getParentById(this.props.match.params.id)

        const histCall = await getUserAdvisorEventHistory(this.props.match.params.id);
        //console.log(prof.data.result);
        const history = histCall.data.result;
        const evtHistCall = await getUserEventhistory(this.props.match.params.id);
        const messCall = await getUserMessageHistory(this.props.match.params.id);

        const evtHist = evtHistCall.data.result;
        const title = prof.data.result.userProfile.firstName + '-' + prof.data.result.userProfile.lastName;
        this.setState({ messages: messCall.data.result, exportTitle: title, activeProfile: prof.data.result, profileLoaded: true, activity: history.filter(flt => flt.type != eventType.StudentNote), notes: history.filter(flt => flt.type == eventType.StudentNote), events: evtHist });

    }
    componentDidUpdate(prevProps, prevState) {


    }

    getLanguage = (input) => {

        const lan = languageOptions.filter(l => l.value == input);
        return lan.length > 0 ? lan[0].label : "English";
    }
    setTab = (event, newVal) => {
        let visibilityStates = {
            infoVis: "tabHidden",
            eventsVis: "tabHidden",
            activityVis: "tabHidden",
            notesVis: "tabHidden",
            progressVis: "tabHidden",
        };

        switch (newVal) {
            case 0:
                visibilityStates.infoVis = "tabVisible";
                break;
            case 1:
                visibilityStates.eventsVis = "tabVisible";
                break;
            case 2:
                visibilityStates.activityVis = "tabVisible";
                break;
            case 3:
                visibilityStates.notesVis = "tabVisible";
                break;
            case 4:
                visibilityStates.progressVis = "tabVisible";
                break;
            default:
                break;
        }

        this.setState({ tab: newVal, ...visibilityStates });
    };



    render() {
        if (!this.state.profileLoaded || !this.context.loaded) {

            return <LoadingIndicator loading />;

        }
        return (

            <div className="container">
                <Link
                    className={"dashboardLink"} onClick={this.props.goBack}
                >
                    &lt; Back
                </Link> <br />
               
                    
        <Grid container spacing={2}>

                                        <Grid item xs={8}>
                                            <Typography variant="h6">
                                                {this.state.activeProfile
                                ? `${this.state.activeProfile.userProfile.firstName} ${this.state.activeProfile.userProfile.lastName}`
                                                    : null}
                                            </Typography>
                                        </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6">
                            Family
                        </Typography>
                        <Typography variant="body1">
                            Prefered Languange: {this.getLanguage(this.state.activeProfile.children[0].family.primaryLanguage) == "Other" ? this.state.activeProfile.children[0].family.otherLanguage : this.getLanguage(this.state.activeProfile.children[0].family.primaryLanguage)}
                        </Typography>  <br />
                        {this.state.activeProfile.children.map((child, index) => (
                            <React.Fragment key={index}>
                                <Link
                                    className="dashboardLink"
                                    onClick={() => this.props.openProfile(child, "Student")}
                                >
                                    Child: {child.userProfile.firstName} {child.userProfile.lastName}
                                </Link>
                                <br />
                            </React.Fragment>
                        ))}
                        <br />
                        
                    </Grid>

            <Grid item xs={12}>
                            <Tabs
                                value={this.state.tab}
                                onChange={this.setTab}
                                variant="fullWidth"
                                sx={{
                                    '.MuiTabs-flexContainer': {
                                        justifyContent: 'space-evenly',
                                    },
                                    '.MuiTab-root': {
                                        border: '1px solid #000',
                                        borderBottom: 'none',
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        marginRight: '4px',
                                        '&:last-child': {
                                            marginRight: 0,
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#123225', 
                                            color: '#fff',
                                        },
                                        '&:hover': {
                                            backgroundColor: '#123225', 
                                            color: '#fff',
                                            opacity: 1,
                                        },
                                    },
                                }}
                            >
                            <Tab label="INFO" />
                            <Tab label="EVENTS" />
                            <Tab label="ACTIVITIES" />
                            <Tab label="NOTES" />
                            <Tab label="PROGRESS" />
                            </Tabs>
            </Grid>
                    <Grid container spacing={2} className={this.state.infoVis}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary" gutterBottom>
                                General Information

                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h7" color="secondary" gutterBottom>
                                Total Hours: {this.state.activeProfile.parentContactInfo.eventHours + this.state.activeProfile.parentContactInfo.advisorHours}
                            </Typography>
                        </Grid>
                    <Grid item xs={12}>
                        <GeneralInfoForm profile={this.state.activeProfile} id={this.props.match.params.id} type={"Parent" } />
                        </Grid>     
            </Grid>
            <Grid container spacing={2} className={this.state.eventsVis}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary" gutterBottom>
                        Events

                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h7" color="secondary" gutterBottom>
                                Total Event Hours: {this.state.activeProfile.parentContactInfo.eventHours}
                            </Typography></Grid>
                        <Grid item xs={12}>
                            <EventsList data={this.state.events} openEvent={this.props.openEvent} />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={this.state.activityVis}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary" gutterBottom>
                                Activities

                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h7" color="secondary" gutterBottom>
                                Total Advisor Hours: {this.state.activeProfile.parentContactInfo.advisorHours}

                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Button onClick={() => this.props.newEntry(this.state.activeProfile)} className="profileActionButton">Add Entry</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button onClick={() => this.props.onContactAttempt(this.state.activeProfile)} className="profileActionButton">Communication Attempt</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <ActivitiesList openEntry={this.props.openEntry} data={this.state.activity} message={this.state.messages} openMessageEntry={this.props.openMessageEntry} />
                </Grid>
                        </Grid>
                       
                        
                        <Grid container spacing={2} className={this.state.notesVis}>
                            <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" gutterBottom>
                                    Notes

                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={() => this.props.newNote(this.state.activeProfile)} className="profileActionButton">Add Note</Button>
                                <Notes data={this.state.notes} openNote={this.props.openNote} />
                            </Grid>
                            </Grid>
                        </Grid>
                    <Grid container spacing={2} className={this.state.progressVis}>
                        <Grid item xs={11}>
                            <Typography variant="h5" color="secondary" gutterBottom>
                                Progress

                            </Typography>
                            <ParentProgress profile={this.state.activeProfile} />
                        </Grid><Grid item xs={1}>
                            <br />
                            <br />
                            <Export2PDF fileName={this.state.exportTitle}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}> <Typography variant="h4" style={{ textAlign: "center", }} >
                                        Progress
                                    </Typography></Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="h6">
                                           &nbsp;     {this.state.activeProfile.userProfile.firstName} {this.state.activeProfile.userProfile.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {moment(Date.now()).format("M/D/YY")}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ParentProgress2Exp profile={this.state.activeProfile} />
                                    </Grid>
                                </Grid>
                            </Export2PDF>
                        </Grid>
                    </Grid>
                </Grid>


                           
            </div>);
    }

}
export default AdvisorParent;