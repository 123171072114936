import axios from "axios";

export const createEvent = (input) => {
    return axios.post("/api/services/app/ProgramEvent/Create", input);
};
export function getIpeds() {
    return axios.get("/api/services/app/Tagging/GetIpeds");
}
export const getAllFiltered = (isActive, searchText, seasonId, department, status, eligibilityCriteria, cancelToken) => {
    return axios.post("/api/services/app/ProgramEvent/GetAllFiltered", {  isActive, searchText, seasonId, department, status, eligibilityCriteria }, { cancelToken });
};

export const getAll = (isActive, searchText, seasonId, department, status, cancelToken) => {
    return axios.get("/api/services/app/ProgramEvent/GetAll", { params: { isActive, searchText, seasonId, department, status }, cancelToken });
};

export const get = (programEventId, cancelToken) => {
    return axios.get("/api/services/app/ProgramEvent/Get", { params: { id: programEventId }, cancelToken });
};

export const getAvailableAdvisors = (cancelToken) => {
    return axios.get("/api/services/app/ProgramEvent/GetAvailableAdvisors", { cancelToken });
};

export const updateEventDetails = (programEvent) => {
    return axios.put("/api/services/app/ProgramEvent/UpdateEventDetails", programEvent);
};

export const getEligibleParticipants = (programEventId, cancelToken) => {
    return axios.get("/api/services/app/ProgramEvent/GetEligibleParticipants", { params: { id: programEventId }, cancelToken });
};

export const getNewInviteCount = (programEventId, cancelToken) => {
    return axios.get("/api/services/app/ProgramEvent/GetNewInviteCount", { params: { id: programEventId }, cancelToken });
};

export const inviteEligibleParticipants = (programEventId, cancelToken) => {
    return axios.post("/api/services/app/ProgramEvent/InviteEligibleParticipants", { id: programEventId }, { cancelToken });
};

export const getAllInstitutions = (cancelToken) => {
    return axios.get("/api/services/app/Institution/GetAllActive", { cancelToken });
};

export const getAllSchools = (cancelToken) => {
    return axios.get("/api/services/app/School/GetAllActive", { cancelToken });
};

export const getAllDistricts = (cancelToken) => {
    return axios.get("/api/services/app/School/GetAllDistricts", { cancelToken });
};

export const saveEligibilityCriteria = (eligibilityCriteria, cancelToken) => {
    return axios.post("/api/services/app/ProgramEvent/CreateOrUpdateEligibilityCriteria", eligibilityCriteria, { cancelToken });
};

export const getEligibilityOverrides = (programEventId, cancelToken) => {
    return axios.get("/api/services/app/ProgramEvent/GetEligibilityOverrides", { params: { id: programEventId }, cancelToken });
};

export const updateEligibilityOverrides = (programEventId, eligibilityOverrides, cancelToken) => {
    const payload = {
        programEventId,
        overrides: eligibilityOverrides,
    };
    return axios.put("/api/services/app/ProgramEvent/UpdateEligibilityOverrides", payload, { cancelToken });
};


export const adminRegistrationInsert = (input) => {
    return axios.post("/api/services/app/ProgramEvent/adminRegistrationInsert", input);
};

export const searchAllPossibleParticipants = (searchText, cancelToken) => {
    return axios.post("/api/services/app/ProgramEvent/SearchAllPossibleParticipants", { searchText }, { cancelToken });
};

export const updateEventStatus = (programEventId, status, enrollmentCap, waitlistCap, waitlistResponseTime, waitlistResponseDuration) => {
    return axios.put("/api/services/app/ProgramEvent/UpdateEventStatus", {
        programEventId,
        status,
        enrollmentCap,
        waitlistCap,
        waitlistResponseTime,
        waitlistResponseDuration,
    });
};

export const updateEventDescription = (input) => {
    return axios.put("/api/services/app/ProgramEvent/UpdateDescription", input);
};

export const updateEventPermissionSlip = (input) => {
    return axios.put("/api/services/app/ProgramEvent/UpdatePermissionSlip", input);
};

export const createOrUpdateInvitation = (input) => {
    return axios.post("/api/services/app/ProgramEvent/CreateOrUpdateInvitation", input);
};

export const getEventDates = (programEventId) => {
    return axios.get("/api/services/app/ProgramEvent/GetEventDates", { params: { programEventId } });
};

export const getRegisteredUsers = (programEventId, programEventDateId) => {
    return axios.get("/api/services/app/ProgramEvent/GetRegisteredUsers", { params: { programEventId, programEventDateId } });
};

export const updateEnrollementListSettings = (input) => {
    return axios.put("/api/services/app/ProgramEvent/UpdateRegistrationListSetting", input);
};

export const unenrollUsers = (ids) => {
    return axios.delete("/api/services/app/ProgramEvent/DeleteUserRegistrationsById", { data: ids });
};

export const exportRegisteredUsers = (programEventId, programEventDateId) => {
    return axios.post("/api/services/app/ProgramEvent/ExportRegisteredUsers", { programEventId, programEventDateId });
};

export const exportWaitlistUsers = (programEventId, programEventDateId) => {
    return axios.post("/api/services/app/ProgramEvent/ExportWaitlistUsers", { programEventId, programEventDateId });
};

export const getWaitlistEventDates = (programEventId) => {
    return axios.get("/api/services/app/ProgramEvent/GetEventDatesForWaitlist", { params: { programEventId } });
};

export const getWaitlistUsers = (programEventId, programEventDateId) => {
    return axios.get("/api/services/app/ProgramEvent/GetWaitlistUsers", { params: { programEventId, programEventDateId } });
};

export const updateWaitlistSettings = (input) => {
    return axios.put("/api/services/app/ProgramEvent/UpdateWaitlistSetting", input);
};

export const removeFromWaitlist = (ids) => {
    return axios.delete("/api/services/app/ProgramEvent/DeleteUserWaitlistRegistrationsById", { data: ids });
};

export const addToEvent = (ids) => {
    return axios.post("/api/services/app/ProgramEvent/CreateUserWaitlistInvitationsById", ids);
};

export const getProgramCodes = () => {
    return axios.get("/api/services/app/ProgramCode/GetAllSubCodes");
};

export const getAttendanceUsers = (ProgramEventDateId, Department) => {
    return axios.get("/api/services/app/ProgramEvent/GetAttendance", { params: { ProgramEventDateId, Department } });
};

export const getPermissionSlipsZip = (programEventDateId) => {
    return axios.get("/api/services/app/ProgramEvent/GetPermissionSlipsZip", { params: { programEventDateId } });
};

export const exportAttendance = (department, programEventDateId) => {
    return axios.post("/api/services/app/ProgramEvent/ExportAttendanceList", { department, programEventDateId });
};

export const updateProgramSubCodeAssignment = (input) => {
    return axios.put("/api/services/app/ProgramEvent/UpdateProgramSubCodeAssignment", input);
};

export const getProgramSubCodeDepartmentAssignments = (programEventId) => {
    return axios.get("/api/services/app/ProgramEvent/GetProgramSubCodeDepartmentAssignments", { params: { programEventId } });
};

export const getSubcodeCreditHours = (assignmentId, department) => {
    return axios.get("/api/services/app/ProgramEvent/GetSubcodeCreditHours", { params: { assignmentId, department } });
};

export const assignUserCreditHours = (input) => {
    return axios.post("/api/services/app/ProgramEvent/AssignUserCreditHours", input);
};

export const approveEventDateAttendance = (id) => {
    return axios.post("/api/services/app/ProgramEvent/ApproveEventDateAttendance", { id });
};
export const CreateTemplateFromProgram = (input) => {
    return axios.post("/api/services/app/ProgramEvent/CreateTemplateFromProgram", input);
};
export const getAllTemplates = () => {
    return axios.get("/api/services/app/ProgramEvent/GetAllTemplates");
};
export const CreateProgramFromTemplate = (input) => {
    return axios.post("/api/services/app/ProgramEvent/CreateProgramFromTemplate", input);
};
export const messageParticipants = (input) => {
    return axios.post("/api/services/app/ProgramEvent/messageParticipants", input);
};
export const deleteTemplate = (input) => {
    return axios.post("/api/services/app/ProgramEvent/DelTemplate", input);
};

export const cancelEvent = (input ) => {
    return axios.post("/api/services/app/ProgramEvent/Cancel",  input);
};

export const cancelEventDate = (input) => {
    return axios.post("/api/services/app/ProgramEvent/CancelDate", input);
};
export const deleteEvent = (programEventId) => {
    return axios.delete("/api/services/app/ProgramEvent/deleteEvent",  {data: {Id: programEventId} } );
};


export const GetUserByIdAsync = (id) => {
    return axios.post("/api/services/app/User/GetUserByIdAsync", id);
};