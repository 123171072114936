import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import ReactTable from "react-table";
import moment from "moment-timezone";
import enumHelper from "../../../support/logic/enumHelper";
import { eventType } from "../../enums.js";
import EditIcon from '@mui/icons-material/Edit';
import { Tabs, Tab } from '@mui/material';
import { Grid, Button, Typography } from '@material-ui/core';


const activityTypes = enumHelper.getSelectOptionsList(eventType)



class ActivitiesList extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            actVis: "tabVisible",
            msgVis: "tabHidden",
            tab: 0


        };
    }

displayDate = (val) => {
        if (!val) return "";

        
        return moment.utc(
            val.endsWith("Z") ? val : `${val}Z` 
        )
            .tz("America/New_York")
            .format("M/D/YY h:mm a");
    };

    componentDidMount() {
        
    }
    componentDidUpdate(prevProps, prevState) {
        


    }

    setTab = (event, newVal) => {
        let actVis = "tabHidden";
        let msgVis = "tabHidden";
        
        switch (newVal) {
            case 0:

                actVis = "tabVisible";

                break;
            case 1:
                msgVis = "tabVisible";
                break;
            

            default:

                break;


        }

        this.setState({ tab: newVal, actVis: actVis, msgVis: msgVis });
    }

    render() {
        
        let columns = [

            {
                Header: "",
                accessor: "id",
                width: 50,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} ><EditIcon onClick={() => this.props.openEntry(row.original) } /></div>
                ),

            }, {
                Header: "Title",
                accessor: "title",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.title}</div>
                ),

            },
            {
                Header: "Description",
                accessor: "description",
                width: 300,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.description}</div>
                ),

            },
            {
                Header: "Credit Hours",
                accessor: "totalAvailableCreditHours",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.totalAvailableCreditHours}</div>
                ),

            },
            {
                Header: "Start Date/Time",
                accessor: "startTime",
                width: 100,
                Cell: (row) => {
                    const lCont = row.value !== null ? moment(row.value).format("M/D/YY h:mm a") : "";
                    return <div className="dataCell" style={{ textAlign: "center" }}>{lCont}</div>;
                },

            }
            ,
            {
                Header: "End Date/Time",
                accessor: "endTime",
                width: 100,
                Cell: (row) => {
                    const lCont = row.value !== null ? moment(row.value).format("M/D/YY h:mm a") : "";
                    return <div className="dataCell" style={{ textAlign: "center" }}>{lCont}</div>;
                },

            }
            ,
            {
                Header: "Activity Type",
                accessor: "type",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{enumHelper.getDisplayName(eventType, row.original.type) }</div>
                ),

            },
            {
                Header: "Created By",
                accessor: "creator",
                width: 300,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.creator}</div>
                ),

            }


        ];
        let messageColumns = [

            {
                Header: "",
                accessor: "id",
                width: 50,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} ><EditIcon onClick={() => this.props.openMessageEntry(row.original)} /></div>
                ),

            },
            {
                Header: "Sender",
                accessor: "sender",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.sender}</div>
                ),

            },
            {
                Header: "Sent Date",
                accessor: "sentTime",
                width: 100,
                Cell: (row) => {
                    return <div className="dataCell" style={{ textAlign: "center" }}>{this.displayDate(row.value)}</div>;
                },

            }
            , {
                Header: "Subject",
                accessor: "subject",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.subject}</div>
                ),

            },
            {
                Header: "Email Body",
                accessor: "content",
                width: 300,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.content}</div>
                ),

            },
            {
                Header: "Text Body",
                accessor: "textBody",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.textBody}</div>
                ),

            },
            {
                Header: "Email Status",
                accessor: "emailStatus",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.emailStatus}</div>
                ),

            }
            ,
            {
                Header: "Text Status",
                accessor: "textStatus",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.textStatus}</div>
                ),

            }
            


        ];
        
        return <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
            <Tabs value={this.state.tab} onChange={this.setTab} aria-label="Activity Sections" variant="fullWidth"
                sx={{
                    '.MuiTabs-flexContainer': {
                        justifyContent: 'space-evenly',
                    },
                    '.MuiTab-root': {
                        border: '1px solid #000',
                        borderBottom: 'none',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        marginRight: '4px',
                        '&:last-child': {
                            marginRight: 0,
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#123225',
                            color: '#fff',
                        },
                        '&:hover': {
                            backgroundColor: '#123225',
                            color: '#fff',
                            opacity: 1,
                        },
                    },
                }}
            >
                <Tab label="Activities" />
                <Tab label="Messages" />
                    </Tabs>
                </Grid>
                <Grid container spacing={2} className={this.state.actVis}>
                    <Grid item xs={12}>
            <ReactTable
                data={this.props.data}
                minRows={0}
                columns={columns}
                defaultPageSize={50}
                className="-striped -highlight tableWrapper"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={this.state.msgVis}>
                    <Grid item xs={12}>
                        <ReactTable
                            data={this.props.message}
                            minRows={0}
                            columns={messageColumns}
                            defaultPageSize={50}
                            className="-striped -highlight tableWrapper"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>;
    }
}

export default ActivitiesList;