import React, { Component } from "react";
import {
    TextField,
    Button,
    Grid,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
} from "@mui/material";
import moment from "moment-timezone";
import './index.css';




class GenericMessageSearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            startDate: moment().subtract(1, "days").format("YYYY-MM-DD"), 
            endDate: moment().format("YYYY-MM-DD"), 
            emailStatus: "",
            textStatus: "",
        };
    }

    displayDate = (val) => {
        if (!val) return "";


        return moment.utc(
            val.endsWith("Z") ? val : `${val}Z`
        )
            .tz("America/New_York")
            .format("M/D/YY h:mm a");
    };
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    };

    handleDateChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value ? new Date(value) : null,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;

        if (onSubmit) {
            const { startDate, endDate, ...rest } = this.state;
            const dataToSubmit = {
                ...rest,
                startDate: startDate
                    ? moment.tz(startDate, "America/New_York").startOf("day").utc().toISOString()
                    : null,
                endDate: endDate
                    ? moment.tz(endDate, "America/New_York").endOf("day").utc().toISOString()
                    : null,
            };
            onSubmit(dataToSubmit);
        }
    };

    render() {
        const { keyword, startDate, endDate, emailStatus, textStatus } = this.state;
        
        return (
            <form onSubmit={this.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label="Keyword"
                            name="keyword"
                            value={keyword}
                            onChange={this.handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Start Date"
                            name="startDate"
                            type="date"
                            value={startDate ? startDate : ""}
                            onChange={this.handleInputChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="End Date"
                            name="endDate"
                            type="date"
                            value={endDate ? endDate: ""}
                            onChange={this.handleInputChange}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <InputLabel>Email Status</InputLabel>
                            <Select
                                name="emailStatus"
                                value={emailStatus}
                                onChange={this.handleInputChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Sent">Sent</MenuItem>
                                <MenuItem value="Delivered">Delivered</MenuItem>
                                <MenuItem value="Opened">Opened</MenuItem>
                                <MenuItem value="Failed">Failed</MenuItem>
                                <MenuItem value="Bounced">Bounced</MenuItem>
                                <MenuItem value="Dropped">Dropped</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <InputLabel>Text Status</InputLabel>
                            <Select
                                name="textStatus"
                                value={textStatus}
                                onChange={this.handleInputChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="Sent">Sent</MenuItem>
                                <MenuItem value="Delivered">Delivered</MenuItem>
                                <MenuItem value="Undelivered">Undelivered</MenuItem>
                                <MenuItem value="Failed">Failed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        
                        <Button type="submit" variant="contained" color="primary" className="messageSearchButton">
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default GenericMessageSearchForm;
